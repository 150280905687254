

export default {
    methods:{

        getTMGeneralReports(params){
            return new Promise((resolve, reject)=>{
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/report/tm/general-reports`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        }
    }
}