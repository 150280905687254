<template>
  <div class="w-100 h-auto">
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h3 class="mb-25 font-weight-bolder">Unified Reports</h3>
            <p class="mb-0">Manage general reports of TM.</p>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-between"
          >
            <!-- <b-button
              size="sm"
              variant="success"
              @click="handleCreateUserClick($store.state.app.user.user_id)"
              ><feather-icon icon="PlusIcon" class="mr-50" />Create New
              User</b-button
            > -->
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.name == null || filters.name == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.name = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.name"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="position-relative table-responsive"
          style="min-height: 60vh"
        >
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col" class="text-center">
                  SL No:
                </th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Status</th>
                <th role="columnheader" scope="col">Created At</th>
                <th role="columnheader" scope="col">Created By</th>
                <!-- <th role="columnheader" scope="col" class="text-center">
                  Actions
                </th> -->
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="reports && Object.keys(reports).length>0">
                <tr
                  @click="handleReportClick"
                  role="row"
                  v-for="(report, key, index) in reports"
                  :key="key"
                >
                  <th scope="row" class="text-center mx-1" style="width: 120px">
                    {{ index + 1 + 10 * (filters.page - 1) }}
                  </th>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 240px"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="`${report.report_filename}`"
                      >
                        {{ report.report_filename }}
                      </p>
                    </div>
                  </td>
                  <td role="cell">
                    <b-badge variant="primary" v-if="report.status == 0"
                      >Scheduled</b-badge
                    >
                    <b-badge variant="warning" v-if="report.status == 1"
                      >In Progress</b-badge
                    >
                    <b-badge variant="success" v-if="report.status == 2"
                      >Generated</b-badge
                    >
                    <b-badge variant="danger" v-if="report.status == 3"
                      >Failed</b-badge
                    >
                    <b-badge variant="success" v-if="report.status == 4"
                      >Uploaded</b-badge
                    >
                  </td>
                  <td role="cell">
                    <span>{{ report.created_at | formatdate }}</span>
                  </td>
                  <td role="cell">
                    <span>{{ report.generated_username }}</span>
                  </td>
                  <!-- <td role="cell">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-dropdown
                      size="sm"
                      class="ml-1"
                      variant="outline-primary"
                      text="Actions"
                    >
                      <b-dropdown-item
                        v-if="report.status == 2 && report.report_pdf_url != null"
                        @click.stop="
                          downloadPDFReport(
                            report.report_id,
                            report.report_name
                          )
                        "
                        >Download Report PDF</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="report.status != 3 && report.status != 0"
                        @click.stop="
                          downloadReport(report.report_id, report.report_name)
                        "
                        >Download Report</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </td> -->
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="No Reports">
                  <template #content>
                    <p class="font-weight-bold text-center">
                     Access <a href="javascript:" @click="handleTMAccessClick">TM portal</a> to generate new reports.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
        <div class="card-body">
          <div
            class="demo-spacing-0 d-flex align-items-center justify-content-end"
          >
            <!-- <p class="align-middle mb-0">
              Showing {{ from + 10 * (filters.page - 1) }} to {{ 10 * filters.page }} of {{ count }} entries
            </p> -->

            <b-pagination
              v-model="filters.page"
              :total-rows="count"
              :per-page="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BPagination,
  BAvatar,
  BBadge,
  VBTooltip,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BDropdownDivider,
  BModal,
  BInputGroupAppend,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import ReportMixins from "@/mixins/ReportMixins";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BPagination,
    BAvatar,
    BBadge,
    FeatherIcon,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BDropdownDivider,
    BModal,
    BInputGroupAppend,
    EmptyTableSection,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [ResponseMixins, ReportMixins],
  data() {
    return {
      showOverlay: false,
      total: 1,
      count: 1,
      from: 1,
      to: 1,
      reports: {},
      filters: {
        name: null,
        page: 1,
      },
    };
  },
  filters: {
    formatdate: function (value) {
      // if (value) {
      //   return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      // }
      if (value) {
        const localTime = new Date(value).toLocaleString();
        return localTime;
      }
    },
  },

  watch: {
    filters: {
      handler: function (newValues) {
        this.reports = {}
        let newParams = {
          ...newValues,
        };
        newParams["query"] = true;
        newParams["org-id"] = this.$store.state.app.tenantId;
        newParams["published"] = true;
        this.getTMGeneralReportsAndSetData(newParams);
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      let newParams = {
        ...this.filters,
      };
      newParams["org-id"] = this.$store.state.app.tenantId;
      this.getTMGeneralReportsAndSetData(newParams);
    },
     handleTMAccessClick() {
      this.$cookies.set(
        "redirectTMPath",
        "",
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      const url = `${process.env.VUE_APP_TM_WEB_URL}${""}`;
      window.open(url, "_blank");
    },

    handleReportClick() {
      this.$cookies.set(
        "redirectTMPath",
        "reports?tabIndex=1",
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      const url = `${process.env.VUE_APP_TM_WEB_URL}${"reports?tabIndex=1"}`;
      window.open(url, "_blank");
    },

    downloadReport(id, name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "report/report/" + id + "/download",
        responseType: "blob",
      };
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Download Successful",
            icon: "DownloadIcon",
            variant: "success",
          },
        });
        // this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
    },

    downloadPDFReport(id, name) {
      var file_name = name.split(".")[0];
      var fileName = file_name + ".pdf";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report/" +
          id +
          "/download-as-pdf",
        responseType: "blob",
      };
      this.$http(options).then((res) => {
        if (res.status === 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "connection error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Completed Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        console.log(res, "pdfff");
      });
    },

    getTMGeneralReportsAndSetData(params) {
      this.showOverlay = true;
      this.getTMGeneralReports(params)
        .then((res) => {
          console.log("TM_GENERAL_REPORTS", res);
          const newReports = {};
          res.data.results.forEach((rp) => {
            newReports[rp.report_id] = rp;
          });
          this.reports = newReports;
          this.total = Math.ceil(parseInt(res.data.count) / 10);
          this.count = parseInt(res.data.count);
          console.log("Total", this.total);
          if (this.filters.page < this.total) {
            this.to = this.filters.page + 1;
          } else {
            this.to = this.filters.page;
          }
          if (this.filters.page > 1) {
            this.from = this.filters.page - 1;
          } else {
            this.from = this.filters.page;
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
